export function isValidIndianPhoneNumber(phoneNumber) {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(phoneNumber);
}


export function isValidEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

export const NEW_COURSE_LIST = [
    {
        name: "Higher Secondary (Arts)",
        code: "HSA"
    },
    {
        name: "Higher Secondary (Science)",
        code: "HSS"
    },
    {
        name: "Higher Secondary (Commerce)",
        code: "HSC"
    },
    {
        name: "Bachelor of Arts",
        code: "BA"
    },
    {
        name: "Bachelor of Science",
        code: "BSC"
    },
    {
        name: "Bachelor of Commerce",
        code: "BCOM"
    },
    {
        name: "Bachelor of Business Administration",
        code: "BBA"
    },
    {
        name: "Bachelor of Computer Application",
        code: "BCA"
    },
    {
        name: "BSC Computer Science",
        code: "BSCCS"
    },
    {
        name: "BA in Journalism & Mass Communication",
        code: "BAJMC"
    },
    {
        name: "BA in Journalism & Mass Communication",
        code: "BAJMC"
    },
    {
        name: "Master of Arts",
        code: "MA"
    },
    {
        name: "Master of Science",
        code: "MSC"
    },
    {
        name: "Diploma in Fine Arts",
        code: "D-FNA"
    },
    {
        name: "Diploma in Performing Arts",
        code: "D-PFA"
    },
]
