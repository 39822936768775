export const BaseURL = process.env.NODE_ENV === 'production' ? 'https://backend.prag.corexx.in/public' : 'http://localhost/prag-main/api/public';

export function PostData(type, userData) {

    //let BaseURL = 'http://api.maadtc.in/public';
    

    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        })
            .then((resp) => {
                if (!resp.ok) {
                    if (resp.status >= 400 && resp.status < 500) {
                        return resp.json().then(data => {
                            let err = { errorMessage: data };
                            alert(err.errorMessage);
                            //logout
                            //Auth.logout();
                            throw err;
                        })
                    }
                    else {
                        let err = { errorMessage: "Please try again later" };
                        alert(`Something went Wrong!! Status: ${resp.status}`);
                        //logout
                        //Auth.logout();
                        throw err;
                    }
                }
                return resp.json();
            }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function GetData(type) {

    //let BaseURL = 'https://api.maadtc.in/public';
    
    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((resp) => {
                if (!resp.ok) {
                    if (resp.status >= 400 && resp.status < 500) {
                        return resp.json().then(data => {
                            let err = { errorMessage: data };
                            alert(err.errorMessage);
                            //logout
                            //Auth.logout();
                            throw err;
                        })
                    }
                    else {
                        let err = { errorMessage: "Please try again later" };
                        alert(`Something went Wrong!! Status: ${resp.status}`);
                        //logout
                        //Auth.logout();
                        throw err;
                    }
                }
                return resp.json();
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function DeleteData(type) {

    
    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Unit': 'STUDENT'
            }
        })
            .then((resp) => {
                if (!resp.ok) {
                    if (resp.status >= 400 && resp.status < 500) {
                        /* return resp.json().then(data => {
                            let err = { errorMessage: data };
                            alert(err.errorMessage);
                            //logout
                            //Auth.logout();
                            throw err;
                        }) */

                        throw resp.text();
                    }
                    else {
                        /* let err = { errorMessage: "Please try again later" };
                        alert(`Something went Wrong!! Status: ${resp.status}`);
                        //logout
                        //Auth.logout();
                        throw err; */
                        throw resp.text();
                    }
                } else {
                    return resp.json();
                }

            }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                error.then((resp) => {
                    console.log(error);
                })
                reject(error);
            });
    });
}

export function PostFormData(type, userData) {

    //let BaseURL = 'https://api.maadtc.in/public';
    //let BaseURL = 'http://localhost/university-app-1/api/public';
    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'POST',
            body: userData,
            headers: {
                
            }
        })
            .then((resp) => {
                //console.log(resp);
                if (!resp.ok) {
                    if (resp.status >= 400 && resp.status < 500) {
                        return resp.json().then(data => {
                            let err = { errorMessage: data };
                            alert(err.errorMessage);
                            //logout
                            //Auth.logout();
                            throw err;
                        })
                    }
                    else {
                        let err = { errorMessage: "Please try again later" };
                        alert(`Something went Wrong!! Status: ${resp.status}`);
                        //logout
                        //Auth.logout();
                        throw err;
                    }
                }
                return resp.json();
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function GetBlob(type) {

    //let BaseURL = 'https://api.maadtc.in/public';
    //let BaseURL = 'http://localhost/university-app-1/api/public';
    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'GET',
            headers: {
                
            }
        })
            .then((resp) => {
                if (!resp.ok) {

                    let err = { errorMessage: "Please try again later" };
                    alert(`Something went Wrong!! Status: ${resp.status}`);
                    throw err;

                }
                return resp.blob();
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}