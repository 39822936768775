import React, { Component } from 'react'
import Grievance from '../Components/Grievance/Grievance';

class HomePage extends Component {
    render() {
        return (
            <div className="jumbotron">
                <Grievance />
            </div>
        )
    }
}
export default HomePage;




