import React, { Component } from "react";
import Grievance from "../Components/Grievance/Grievance";
import { PostData } from "../api/service";
import { Result, Descriptions } from "antd";
import queryString from "query-string";


class VerificationPage extends Component {
  state = {
    certificate_no: "",
    isLoaded: false,
    data: null,
    status: null,
    status_text: "",
  };

  componentDidMount(){
    let search = this.props.history.location.search;

    let searchData = queryString.parse(search);

    if (searchData && searchData.certificate_no && searchData.certificate_no != "" && searchData.certificate_no != undefined) {
      this.setState({
        certificate_no: atob(searchData.certificate_no), // decrypting response
        
      }, () => {
        this.validateCertificate(this.state.certificate_no)
      });
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validateCertificate = (certificate_no) => {
    // post the data to the server
    PostData(`/validate-certificate`, {
      certificate_no: certificate_no,
    }).then((resp) => {
      this.setState({
        status: resp.status,
        data: resp.data,
        status_text: resp.status_text,
        isLoaded: true,
      });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    // search for certificate validation
    if (this.state.certificate_no != "") {
      this.validateCertificate(this.state.certificate_no);
    }
  };
  render() {
    return (
      <div className="jumbotron">
        <div className="container">
          <div className="reg-form">
            <div className="top-bar"></div>
            <div className="form-contents">
              <h3
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ marginLeft: 0, fontWeight: 700 }}>
                  Verification for Certificates
                  <br />
                  <div style={{ fontSize: 16 }}>
                    Pragjyotish College, Santipur,Kamrup(M),Assam (India) Pin
                    781009
                  </div>
                </div>
              </h3>
              <hr />
              <h6>** Search here with certificate ID</h6>
              <hr />

              <form className="form" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-md-10">
                    <input
                      className="form-control input-sm"
                      placeholder="Search here"
                      name="certificate_no"
                      value={this.state.certificate_no}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="col-md-2">
                    <button
                      className="btn btn-sm btn-primary"
                      type="submit"
                      style={{ backgroundColor: "#0029CB" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <br />

          {this.state.status == true && <div className="reg-form">
            <Result
              status="success"
              title={`Certificate #${this.state.data.certificate_sl_no} is found !!`}
              subTitle="Here are some details."
            >
             <Descriptions column={1} bordered={true} size="small">
              <Descriptions.Item label="Certificate Type">
                <strong>{this.state.data.type_name}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Applied By">
                {this.state.data.name}
              </Descriptions.Item>
              <Descriptions.Item label="Course">
                {this.state.data.course}
              </Descriptions.Item>
              <Descriptions.Item label="Semester/Year">
                {this.state.data.semester}
              </Descriptions.Item>
              <Descriptions.Item label="Father's Name">
                {this.state.data.father_name}
              </Descriptions.Item>
              <Descriptions.Item label="Mother's Name">
                {this.state.data.mother_name}
              </Descriptions.Item>
              
              <Descriptions.Item label="Roll No">
                {this.state.data.roll_no}
              </Descriptions.Item>
              <Descriptions.Item label="Applied on">
                {this.state.data.date_of_application}
              </Descriptions.Item>
              <Descriptions.Item label="Submitted On">
                {this.state.data.time_stamp}
              </Descriptions.Item>
              <Descriptions.Item label="Verified On">
                {this.state.data.verification_status_on}
              </Descriptions.Item>
              <Descriptions.Item label="Generated On">
                {this.state.data.generated_on}
              </Descriptions.Item>
              </Descriptions>
            </Result>
          </div>}
          

          {this.state.status == false && <div className="reg-form">
            <Result
              status="error"
              title={`Certificate #${this.state.certificate_no} not found !!`}
              subTitle="It seems like the certificate is not found on the system."
            />
           
          </div>}
                  </div>
      </div>
    );
  }
}
export default VerificationPage;
