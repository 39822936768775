import React, { Component } from "react";
import "./css/greivance.css";
import FormOne from "./FormOne";
import { PostData } from "./../../api/service";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class Grievance extends Component {


  state = {
    isSubmitting: false
  }
  onSubmit(data) {

    this.setState({
      isSubmitting: true
    })

    PostData("/add-certificate-request", data).then((resp) => {
      this.setState({
        isSubmitting: false
      })
      if (resp.status == true) {
        confirmAlert({
          title: "Sucessfully Submitted ! Proceed to Payment Now.",
          message: "You will receive notifications thought email",
          buttons: [
            {
              label: "Okay",
              onClick: () => (window.location.href = `https://admission.pragjyotishcollege.ac.in/certificate-fees-payment?certificate_request_id=${resp.certificate_request_id}&email=${resp.email}`),
            },
          ],
        });
      }else{
        confirmAlert({
          title: "Previous unsettled request found",
          message: "Click to view the receipt",
          buttons: [
            {
              label: "Okay",
              onClick: () => (window.location.href = `https://admission.pragjyotishcollege.ac.in/certificate-fees-payment?certificate_request_id=${resp.certificate_request_id}&email=${resp.email}`),
            },
          ],
        });
      }
    }).catch((err) => {
      this.setState({
        isSubmitting: false
      })
    })
  }
  render() {
    return (
      <div className="container">
        <div className="reg-form">
          <div className="top-bar"></div>
          <div className="form-contents">
            <h3
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div style={{ marginLeft: 10, fontWeight: 700 }}>
                Application for Certificates
                <br />
                <div style={{ fontSize: 16 }}>Pragjyotish College</div>
              </div>
            </h3>
            <hr />
            <h6>
              ** Please enter valid phone number and email ID. All the updates
              about the certificate will be informed through email.
            </h6>
            <hr />
            <FormOne onSubmit={this.onSubmit.bind(this)} isSubmitting={this.state.isSubmitting} />
          </div>
        </div>
      </div>
    );
  }
}
