import React, { Component } from "react";
import Rich from "../RichText/RichTextEditor";
import { GetData } from "../../api/service";
import {
  NEW_COURSE_LIST,
  isValidEmail,
  isValidIndianPhoneNumber,
} from "../../Helper/GlobalConstants";
import FileUpload from "./FileUpload";
import {LinearProgress} from '@material-ui/core'

export default class FormOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      certificate_id: "",
      name: "",
      father_name: "",
      mother_name: "",
      gender: "",
      stream: "",
      address: "",
      has_library_card: "",
      library_card_no: "",
      course: "",
      semester: "",
      roll_no: "",
      dept_name: "",
      university_roll_no: "",
      cgpa: "", // new
      year: "", // new
      session: "", // new
      is_studying: "", // new
      passing_month: "", // new
      subject: "", // replace - major
      registration_no: "",
      registration_year: "",
      marksheet_file: [],
      registration_file: [],
      phone: "",
      email: "",
      error: {
        certificate_id: "",
        name: "",
        father_name: "",
        mother_name: "",
        gender: "",
        stream: "",
        address: "",
        course: "",
        semester: "",
        dept_name: "",
        roll_no: "",
        has_library_card: "",
        library_card_no: "",
        university_roll_no: "",
        cgpa: "", // new
        year: "", // new
        session: "", // new
        is_studying: "", // new
        passing_month: "", // new
        subject: "", // replace - major
        registration_no: "",
        registration_year: "",
        marksheet_file: "",
        registration_file: "",
        phone: "",
        email: "",
      },
      certificate_types: [],
      courses: [],
    };

    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEditorChange(e) {
    this.setState({
      message: e,
    });
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onFileChange(name, e) {
    this.setState({
      [name]: e,
    });
  }

  componentDidMount() {
    this.__fetchData();
  }

  __fetchData() {
    GetData(`/getcourse`).then((resp) => {
      GetData(`/getcertificatetype`).then((resp1) => {
        this.setState({
          certificate_types: resp1,
          courses: resp,
        });
      });
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
     


      const data = {
        certificate_id: this.state.certificate_id,
        name: this.state.name,
        father_name: this.state.father_name,
        mother_name: this.state.mother_name,
        gender: this.state.gender,
        address: this.state.address,
        stream: this.state.stream,
        course: this.state.course,
        semester: this.state.semester,
        roll_no: this.state.roll_no,
        phone: this.state.phone,
        email: this.state.email,
        dept_name: this.state.dept_name,

        has_library_card: this.state.has_library_card,
        library_card_no: this.state.library_card_no,

        registration_file: this.state.registration_file,
        marksheet_file: this.state.marksheet_file,
        
        university_roll_no: this.state.university_roll_no,
        registration_no: this.state.registration_no,
        registration_year: this.state.registration_year,
        cgpa: this.state.cgpa, // new
        year: this.state.year, // new
        session: this.state.session, // new
        is_studying: this.state.is_studying, // new
        passing_month: this.state.passing_month, // new
        subject: this.state.subject, // replace - major
      };

      this.props.onSubmit(data);

      //clear the state

      this.setState({
        certificate_id: "",
        name: "",
        father_name: "",
        mother_name: "",
        stream: "",
        gender: "",
        address: "",
        course: "",
        has_library_card: "",
        library_card_no: "",
        dept_name: "",
        semester: "",
        roll_no: "",
        university_roll_no: "",
        cgpa: "", // new
        year: "", // new
        session: "", // new
        is_studying: "", // new
        passing_month: "", // new
        subject: "", // replace - major
        registration_no: "",
        registration_year: "",
        registration_file: [],
        marksheet_file: [],
        phone: "",
        email: "",
        error: {
          certificate_id: "",
          name: "",
          father_name: "",
          mother_name: "",
          gender: "",
          address: "",
          has_library_card: "",
          library_card_no: "",
          course: "",
          semester: "",
          dept_name: "",
          registration_file: "",
          marksheet_file: "",
          roll_no: "",
          university_roll_no: "",
          cgpa: "", // new
          year: "", // new
          session: "", // new
          is_studying: "", // new
          passing_month: "", // new
          subject: "", // replace - major
          registration_no: "",
          registration_year: "",
          phone: "",
          email: "",
        },
      });
    }
  }

  handleValidation() {
    let isValid = true;
    let err = [];

    if (this.state.certificate_id == "" || this.state.certificate_id == null) {
      isValid = false;
      err["certificate_id"] = "Mandatory field";
    } else {
      err["certificate_id"] = "";
    }

    if (this.state.course == "" || this.state.course == null) {
      isValid = false;
      err["course"] = "Mandatory field";
    } else {
      err["course"] = "";
    }

    // if(this.state.course == "PG"){
    //   if (this.state.stream == "" || this.state.stream == null) {
    //     isValid = false;
    //     err["stream"] = "Mandatory field";
    //   } else {
    //     err["stream"] = "";
    //   }
    // }

    if (this.state.semester == "" || this.state.semester == null) {
      isValid = false;
      err["semester"] = "Mandatory field";
    } else {
      err["semester"] = "";
    }

    if (this.state.name == "" || this.state.name == null) {
      isValid = false;
      err["name"] = "Mandatory field";
    } else {
      err["name"] = "";
    }

    if (this.state.father_name == "" || this.state.father_name == null) {
      isValid = false;
      err["father_name"] = "Mandatory field";
    } else {
      err["father_name"] = "";
    }


    if (this.state.has_library_card == "" || this.state.has_library_card == null) {
      isValid = false;
      err["has_library_card"] = "Mandatory field";
    } else {
      err["has_library_card"] = "";
    }

    if(this.state.has_library_card == "YES"){
      if (this.state.library_card_no == "" || this.state.library_card_no == null) {
        isValid = false;
        err["library_card_no"] = "Mandatory field";
      } else {
        err["library_card_no"] = "";
      }
    }



    if (this.state.mother_name == "" || this.state.mother_name == null) {
      isValid = false;
      err["mother_name"] = "Mandatory field";
    } else {
      err["mother_name"] = "";
    }

    if (this.state.gender == "" || this.state.gender == null) {
      isValid = false;
      err["gender"] = "Mandatory field";
    } else {
      err["gender"] = "";
    }

    if (this.state.address == "" || this.state.address == null) {
      isValid = false;
      err["address"] = "Mandatory field";
    } else {
      err["address"] = "";
    }

    if (this.state.roll_no == "" || this.state.roll_no == null) {
      isValid = false;
      err["roll_no"] = "Mandatory field";
    } else {
      err["roll_no"] = "";
    }


    if (this.state.registration_file.length == 0) {
      isValid = false;
      err["registration_file"] = "Mandatory field";
    } else {
      err["registration_file"] = "";
    }

    if (this.state.marksheet_file.length == 0) {
      isValid = false;
      err["marksheet_file"] = "Mandatory field";
    } else {
      err["marksheet_file"] = "";
    }

    if (this.state.phone == "" || this.state.phone == null) {
      isValid = false;
      err["phone"] = "Mandatory field";
    } else {
      err["phone"] = "";
    }

    if (!isValidIndianPhoneNumber(this.state.phone)) {
      isValid = false;
      err["phone"] = "Insert a valid phone number";
    } else {
      err["phone"] = "";
    }

    if (this.state.email == "" || this.state.email == null) {
      isValid = false;
      err["email"] = "Mandatory field";
    } else {
      err["email"] = "";
    }

    if (!isValidEmail(this.state.email)) {
      isValid = false;
      err["email"] = "Insert a valid email id";
    } else {
      err["email"] = "";
    }

    if (this.getCodeFromCertificate() == "BC") {
      if (this.state.session == "" || this.state.session == null) {
        isValid = false;
        err["session"] = "Mandatory field";
      } else {
        err["session"] = "";
      }
    }

    if (this.getCodeFromCertificate() == "CC") {
      if (this.state.year == "" || this.state.year == null) {
        isValid = false;
        err["year"] = "Mandatory field";
      } else {
        err["year"] = "";
      }

      if (this.state.is_studying == "" || this.state.is_studying == null) {
        isValid = false;
        err["is_studying"] = "Mandatory field";
      } else {
        err["is_studying"] = "";
      }
    }

    if (this.getCodeFromCertificate() == "MPC") {
      if (
        this.state.university_roll_no == "" ||
        this.state.university_roll_no == null
      ) {
        isValid = false;
        err["university_roll_no"] = "Mandatory field";
      } else {
        err["university_roll_no"] = "";
      }

      if (
        this.state.registration_no == "" ||
        this.state.registration_no == null
      ) {
        isValid = false;
        err["registration_no"] = "Mandatory field";
      } else {
        err["registration_no"] = "";
      }

      if (
        this.state.registration_year == "" ||
        this.state.registration_year == null
      ) {
        isValid = false;
        err["registration_year"] = "Mandatory field";
      } else {
        err["registration_year"] = "";
      }

      if (this.state.session == "" || this.state.session == null) {
        isValid = false;
        err["session"] = "Mandatory field";
      } else {
        err["session"] = "";
      }

      if (this.state.subject == "" || this.state.subject == null) {
        isValid = false;
        err["subject"] = "Mandatory field";
      } else {
        err["subject"] = "";
      }

      if (this.state.year == "" || this.state.year == null) {
        isValid = false;
        err["year"] = "Mandatory field";
      } else {
        err["year"] = "";
      }

      if (this.state.passing_month == "" || this.state.passing_month == null) {
        isValid = false;
        err["passing_month"] = "Mandatory field";
      } else {
        err["passing_month"] = "";
      }

      if (this.state.cgpa == "" || this.state.cgpa == null) {
        isValid = false;
        err["cgpa"] = "Mandatory field";
      } else {
        err["cgpa"] = "";
      }
    }

    if (this.getCodeFromCertificate() == "PPC") {

      if (
        this.state.university_roll_no == "" ||
        this.state.university_roll_no == null
      ) {
        isValid = false;
        err["university_roll_no"] = "Mandatory field";
      } else {
        err["university_roll_no"] = "";
      }


      if (this.state.year == "" || this.state.year == null) {
        isValid = false;
        err["year"] = "Mandatory field";
      } else {
        err["year"] = "";
      }

      if (this.state.subject == "" || this.state.subject == null) {
        isValid = false;
        err["subject"] = "Mandatory field";
      } else {
        err["subject"] = "";
      }

      if (this.state.cgpa == "" || this.state.cgpa == null) {
        isValid = false;
        err["cgpa"] = "Mandatory field";
      } else {
        err["cgpa"] = "";
      }
    }

    if (this.getCodeFromCertificate() == "TC") {
      if (this.state.is_studying == "" || this.state.is_studying == null) {
        isValid = false;
        err["is_studying"] = "Mandatory field";
      } else {
        err["is_studying"] = "";
      }

      if (this.state.subject == "" || this.state.subject == null) {
        isValid = false;
        err["subject"] = "Mandatory field";
      } else {
        err["subject"] = "";
      }

      if (this.state.cgpa == "" || this.state.cgpa == null) {
        isValid = false;
        err["cgpa"] = "Mandatory field";
      } else {
        err["cgpa"] = "";
      }

      if (this.state.year == "" || this.state.year == null) {
        isValid = false;
        err["year"] = "Mandatory field";
      } else {
        err["year"] = "";
      }
    }

    this.setState({
      error: err,
    });
    return isValid;
  }

  getAmountFromCertificate() {
    let cer = this.state.certificate_types;
    let ff = cer.find((el) => el.id == this.state.certificate_id);

    if (ff) {
      return ff.registration_fee;
    }
  }

  getCodeFromCertificate() {
    let cer = this.state.certificate_types;
    let ff = cer.find((el) => el.id == this.state.certificate_id);

    if (ff) {
      return ff.type_code;
    }
  }

  render() {
    return (
      <div>
        <form className="form" onSubmit={this.handleSubmit}>
          <div
            className="row"
            style={{ backgroundColor: "#F4ECF7", padding: 10 }}
          >
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  Type of Certificate your are applying{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className="form-control"
                  type="text"
                  required
                  name="certificate_id"
                  value={this.state.certificate_id}
                  onChange={this.onChange}
                >
                  <option value={``} disabled>
                    Select
                  </option>
                  {this.state.certificate_types.map((el, index) => (
                    <option value={`${el.id}`} key={index}>
                      {el.type_name}
                    </option>
                  ))}
                </select>
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.certificate_id}
                </div>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ backgroundColor: "#F4ECF7", padding: 10 }}
          >
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Course <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className="form-control"
                  type="text"
                  required
                  name="course"
                  value={this.state.course}
                  onChange={this.onChange}
                >
                  <option value={``} disabled>
                    Select
                  </option>
                  {NEW_COURSE_LIST.map((el, index) => (
                    <option value={`${el.code}`} key={index}>
                      {el.name}
                    </option>
                  ))}
                </select>
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.course}
                </div>
              </div>
            </div>


            {/* {this.state.course == "PG" && <div className="col-md-4">
              <div className="form-group">
                <label>
                  Stream <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className="form-control"
                  type="text"
                  required
                  name="stream"
                  value={this.state.stream}
                  onChange={this.onChange}
                >
                  <option value={``} disabled>
                    Select
                  </option>
                  {["ARTS","SCIENCE"].map((el, index) => (
                    <option value={`${el}`} key={index}>
                      {el}
                    </option>
                  ))}
                </select>
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.stream}
                </div>
              </div>
            </div>} */}

            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Semester / Year <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className="form-control"
                  type="text"
                  required
                  name="semester"
                  value={this.state.semester}
                  onChange={this.onChange}
                >
                  <option value={``} disabled>
                    Select
                  </option>
                  {[1, 2, 3, 4, 5, 6].map((el, index) => (
                    <option value={`${el}`} key={index}>
                      {el}
                    </option>
                  ))}
                </select>
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.semester}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Full Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  required
                  value={this.state.name}
                  onChange={this.onChange}
                />
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.name}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Father's Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="father_name"
                  required
                  value={this.state.father_name}
                  onChange={this.onChange}
                />
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.father_name}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Mother's Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="mother_name"
                  required
                  value={this.state.mother_name}
                  onChange={this.onChange}
                />
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.mother_name}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Gender <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className="form-control"
                  type="text"
                  required
                  name="gender"
                  value={this.state.gender}
                  onChange={this.onChange}
                >
                  <option value={``} disabled>
                    Select
                  </option>
                  {["MALE", "FEMALE", "OTHERS"].map((el, index) => (
                    <option value={`${el}`} key={index}>
                      {el}
                    </option>
                  ))}
                </select>
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.gender}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Address <span style={{ color: "red" }}>*</span>
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  name="address"
                  value={this.state.address}
                  onChange={this.onChange}
                />
              </div>
              <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                {this.state.error.address}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>
                  College Roll No <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="roll_no"
                  required
                  value={this.state.roll_no}
                  onChange={this.onChange}
                />
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.roll_no}
                </div>
              </div>
            </div>


            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Department (if applicable) 
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="dept_name"
                  required
                  value={this.state.dept_name}
                  onChange={this.onChange}
                />
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.dept_name}
                </div>
              </div>
            </div>

            <div className="col-md-7">
              <div className="form-group">
                <label>
                  Do you have Library Card ?
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className="form-control"
                  type="text"
                  required
                  name="has_library_card"
                  value={this.state.has_library_card}
                  onChange={this.onChange}
                >
                  <option value={``} disabled>
                    Select
                  </option>
                  {["YES","NO"].map((el, index) => (
                    <option value={`${el}`} key={index}>
                      {el}
                    </option>
                  ))}
                </select>
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.has_library_card}
                </div>
              </div>
            </div>

            {this.state.has_library_card == "YES" && <div className="col-md-4">
              <div className="form-group">
                <label>
                  Library Card ID/User ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="library_card_no"
                  required
                  value={this.state.library_card_no}
                  onChange={this.onChange}
                />
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.library_card_no}
                </div>
              </div>
            </div>}


          </div>

          <br />

          {this.getCodeFromCertificate() == "BC" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Session
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="session"
                      value={this.state.session}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.session}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.getCodeFromCertificate() == "CC" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Year
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="year"
                      value={this.state.year}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.year}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Are you still studying ?
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      type="text"
                      required
                      name="is_studying"
                      value={this.state.is_studying}
                      onChange={this.onChange}
                    >
                      <option value={``} disabled>
                        Select
                      </option>
                      {["YES", "NO"].map((el, index) => (
                        <option value={`${el}`} key={index}>
                          {el}
                        </option>
                      ))}
                    </select>
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.is_studying}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.getCodeFromCertificate() == "MPC" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      University Roll No
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="university_roll_no"
                      value={this.state.university_roll_no}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.university_roll_no}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      University Registration No
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="registration_no"
                      value={this.state.registration_no}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.registration_no}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      University Registration Year
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="registration_year"
                      value={this.state.registration_year}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.registration_year}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Session
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="session"
                      value={this.state.session}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.session}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Subject
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="subject"
                      value={this.state.subject}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.subject}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Passing Year
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="year"
                      value={this.state.year}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.year}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Passing Month
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="passing_month"
                      value={this.state.passing_month}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.passing_month}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      CGPA
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="cgpa"
                      value={this.state.cgpa}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.cgpa}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.getCodeFromCertificate() == "TC" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Are you still studying ?
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      type="text"
                      required
                      name="is_studying"
                      value={this.state.is_studying}
                      onChange={this.onChange}
                    >
                      <option value={``} disabled>
                        Select
                      </option>
                      {["YES", "NO"].map((el, index) => (
                        <option value={`${el}`} key={index}>
                          {el}
                        </option>
                      ))}
                    </select>
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.is_studying}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Subject
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="subject"
                      value={this.state.subject}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.subject}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      CGPA
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="cgpa"
                      value={this.state.cgpa}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.cgpa}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Year
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="year"
                      value={this.state.year}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.year}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.getCodeFromCertificate() == "PPC" && (
            <div>
              <div className="row">

              <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      University Roll No
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="university_roll_no"
                      value={this.state.university_roll_no}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.university_roll_no}
                    </div>
                  </div>
                </div>

                
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Subject
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="subject"
                      value={this.state.subject}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.subject}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      CGPA
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="cgpa"
                      value={this.state.cgpa}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.cgpa}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Year
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="year"
                      value={this.state.year}
                      onChange={this.onChange}
                    />
                    <div
                      style={{ color: "red", fontWeight: 600, fontSize: 12 }}
                    >
                      {this.state.error.year}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <hr />

          <div
            className="row"
            style={{ backgroundColor: "#F2F4F4", padding: 10 }}
          >
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Phone Number <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  className="form-control input-sm"
                  placeholder="Phone Number"
                  name="phone"
                  value={this.state.phone}
                  onChange={this.onChange}
                  required={true}
                />
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.phone}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Email id <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  className="form-control input-sm"
                  placeholder="Email id"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  required={true}
                />
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.email}
                </div>
              </div>
            </div>
          </div>


        

          <div
            className="row"
            style={{ padding: 10 }}
          >
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Upload Marksheet <span style={{ color: "red" }}>*</span>
                </label>
                <FileUpload apikey={`CFFB77C9B42CCB16A238AD63CBA1DA1B28E571C`} onChange={(e) => this.onFileChange('marksheet_file', e)} type={`CERTIFICATE_MARKSHEET`}  data={this.state.marksheet_file}/>
                
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.marksheet_file}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Update Registration Certificate (Upload Admission Receipt for Bonafide certificate) <span style={{ color: "red" }}>*</span>
                </label>
                <FileUpload apikey={`CFFB77C9B42CCB16A238AD63CBA1DA1B28E571C`} onChange={(e) => this.onFileChange('registration_file', e)} type={`CERTIFICATE_REGISTRATION`}  data={this.state.registration_file}/>
                <div style={{ color: "red", fontWeight: 600, fontSize: 12 }}>
                  {this.state.error.registration_file}
                </div>
              </div>
            </div>
          </div>

          <br />

          {this.state.certificate_id != "" && (
            <div class="alert alert-success" role="alert">
              You have to make a payment of amount Rs.{" "}
              {this.getAmountFromCertificate()}
            </div>
          )}

          <br />

          {this.props.isSubmitting && <LinearProgress />}

          <br />

          <div className="row">
            <div className="col-md-12" align="center">
              <div className="form-group">
                <button
                  className="btn btn-sm btn-primary"
                  type="submit"
                  style={{ backgroundColor: "#0029CB" }}
                  disabled={this.props.isSubmitting}
                >
                
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
