import React, { Component, createRef } from "react";
import Grievance from "../Components/Grievance/Grievance";
import { PostData } from "../api/service";
import { Result, Descriptions, Button } from "antd";
import queryString from "query-string";
import "./css/certificate-print.css";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { NEW_COURSE_LIST } from "../Helper/GlobalConstants";


class CertificatePrintPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certificate_no: "",
      isLoaded: false,
      data: null,
      status: null,
      status_text: "",
    };
    this.divRef = createRef();
  }
  

  componentDidMount() {
    let search = this.props.history.location.search;

    let searchData = queryString.parse(search);
    var base64regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

    if (
      searchData &&
      searchData.certificate_no &&
      searchData.certificate_no != "" &&
      searchData.certificate_no != undefined &&
      base64regex.test(searchData.certificate_no)
    ) {
      this.setState(
        {
          certificate_no: atob(searchData.certificate_no), // decrypting response
        },
        () => {
          this.validateCertificate(this.state.certificate_no);
        }
      );
    } else {
      this.setState({
        status: false,
        status_text: "Not properly encoded",
      });
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };


 

  downloadPdf = () => {
    const input = this.divRef.current;
    const scale = window.devicePixelRatio;
    html2canvas(input, {
      scale: scale
    })
      .then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        // const pdfWidth = pdf.internal.pageSize.getWidth();
        // const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        const pdfWidth = 210;
        const pdfHeight = 297;
        
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`certificate_${this.state.certificate_no}.pdf`);
      })
      .catch(err => {
        console.error('Error generating PDF:', err);
      });
  };

  validateCertificate = (certificate_no) => {
    // post the data to the server
    PostData(`/validate-certificate-and-print`, {
      certificate_no: certificate_no,
    }).then((resp) => {
      this.setState({
        status: resp.status,
        data: resp.data,
        status_text: resp.status_text,
        isLoaded: true,
      });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    // search for certificate validation
    if (this.state.certificate_no != "") {
      this.validateCertificate(this.state.certificate_no);
    }
  };

  goodSemester = (course, semester) => {
    if (course == "HSA" || course == "HSS" || course == "HSC") {
      if (semester == 1) {
        return "1st Year";
      }
      if (semester == 2) {
        return "2nd Year";
      }
    } else {
      if (semester == 1) {
        return "1st Semester";
      }
      if (semester == 2) {
        return "2nd Semester";
      }
      if (semester == 3) {
        return "3rd Semester";
      }
      if (semester == 4) {
        return "4th Semester";
      }
      if (semester == 5) {
        return "5th Semester";
      }
      if (semester == 6) {
        return "6th Semester";
      }
    }
  };


  goodSemesterNew = (course, semester) => {

    let course_string = "";

   let course_data = NEW_COURSE_LIST.find(el => el.code == course);
    if(course_data){
      course_string = course_data.name;
    }
   
    if(semester == 0){
      return course_string;
    }

    if (course == "HSA" || course == "HSS" || course == "HSC") {
      if (semester == 1) {
        return course_string + " 1st Year";
      }
      if (semester == 2) {
        return course_string +  " 2nd Year";
      }
    } else {
      if (semester == 1) {
        return course_string +  " 1st Semester";
      }
      if (semester == 2) {
        return course_string +  " 2nd Semester";
      }
      if (semester == 3) {
        return course_string +  " 3rd Semester";
      }
      if (semester == 4) {
        return course_string +  " 4th Semester";
      }
      if (semester == 5) {
        return course_string +  " 5th Semester";
      }
      if (semester == 6) {
        return course_string +  " 6th Semester";
      }
    }
  };
  render() {
    return (
      <div className="">
        <div className="">
          <div className="reg-form">
            <div className="top-bar"></div>
            <div className="form-contents">
              <h3
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <div style={{ marginLeft: 0, fontWeight: 700, textAlign: "center", width: "100%" }}>
                  Digital Certificate
                  <br />
                  <div style={{ fontSize: 16, marginTop: 5, textAlign: "center" }}>
                    Generated by Pragjyotish College, Santipur,Kamrup(M),Assam
                    (India) Pin 781009
                  </div>
                </div>
              </h3>
            </div>
          </div>

          <br />

          {this.state.status == true && <div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: 20, backgroundColor: "white"}}>
          {/* <ReactToPrint
                trigger={() => (
                  
                )}
                ref={(el) => (this.buttonRef = el)}
                content={() => this.componentRef}
                pageStyle={{  }}
                copyStyles={true}
                
              /> */}

              <Button type="primary" onClick={this.downloadPdf}>Download</Button>
            
          </div>}

          <br />

          {this.state.status == true && (
            <div className="reg-form1" style={{display: "flex", justifyContent: "center"}} >
              <div class="pm-certificate-container" ref={this.divRef}>
            
                <div class="outer-border"></div>
                <div class="inner-border"></div>

                <div
                  class="pm-certificate-border col-xs-12"
                  style={{ paddingTop: `25px`, position:"absolute", left: "50%" }}
                >
                <div>
                  <h4 className="small-text-new-top">
                          SL NO: {this.state.data.certificate_sl_no}
                        </h4>
                </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        flexBasis: "20%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src="/icon512.png" style={{ width: 120 }} />
                    </div>
                    <div
                      class="row pm-certificate-header"
                      style={{ flexBasis: "60%" }}
                    >
                    
                      <div class="pm-certificate-title sans col-xs-12 text-center ">
                        <h4 className="small-text-new">
                          OFFICE OF THE PRINCIPAL
                        </h4>
                      </div>
                      <div class="pm-certificate-title sans col-xs-12 text-center ">
                        <h4 className="title-text-new">PRAGJYOTISH COLLEGE</h4>
                      </div>
                      <div class="pm-certificate-title sans col-xs-12 text-center ">
                        <h4 className="small-text-new-1">
                          GUWAHATI - 781 009, ASSAM
                        </h4>
                      </div>
                      <div class="pm-certificate-title sans col-xs-12 text-center ">
                        <h4 className="small-text-new-1">Estd. - 1954</h4>
                      </div>
                    </div>
                    <div
                      style={{
                        flexBasis: "20%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <QRCode
                        value={this.state.data.qr_link}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "110px",
                        }}
                      />
                    </div>
                  </div>

                  <div
                    class="pm-certificate-title sans col-xs-12 text-center"
                    style={{ paddingLeft: 60, paddingRight: 60 }}
                  >
                    <p className="small-curved-new">
                      <i>
                        Permanently Affiliated to Gauhati University :
                        Registered under 2 (f) & 12 (B) of UGC Act : Assessed &
                        Accredited by National Assessment & Accreditation
                        Council, Bangalore
                      </i>
                    </p>
                  </div>

                  <div
                    class="pm-certificate-title sans col-xs-12 text-center"
                    style={{ marginBottom: 40 }}
                  >
                    <span className="small-curved-new-2">
                      {this.state.data.type_name}
                    </span>
                  </div>

                  {this.state.data.type_code == "BC" && (
                    <div>
                      <div
                        className="col-xs-12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className="row"
                          style={{ width: "85%", textAlign: "justify" }}
                        >
                          <div className="col-xs-2"></div>
                          <div className="pm-earned ">
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is to certify that
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0">
                              <span className="pm-name-text bold">
                                {this.state.data.name}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text padding-0 cursive">
                              {this.state.data.gender == "MALE" ? "Son" : "Daughter"} of
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0 text-center">
                              <span className="pm-name-text bold">
                                {this.state.data.father_name}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text padding-0 cursive">
                              is a bonafide student of
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0 text-center">
                              <span className="pm-name-text bold">
                                
                                {this.goodSemesterNew(
                                  this.state.data.course,
                                  this.state.data.semester
                                )}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text padding-0 cursive">
                              , Pragjyotish College, Guwahati for the session
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0 text-center">
                              <span className="pm-name-text bold">
                                {this.state.data.session}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text padding-0 cursive">
                              bearing Roll No.
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0 text-center">
                              <span className="pm-name-text bold">
                                {this.state.data.roll_no}
                              </span>
                            </span>
                          </div>
                          <div className="col-xs-2"></div>
                          <div className="col-xs-12"></div>

                          <div className="pm-earned text-center">
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              I wish {this.state.data.gender == "MALE" ? "him" : "her"} success in life.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}


                  {this.state.data.type_code == "CC" && (
                    <div>
                      <div
                        className="col-xs-12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className="row"
                          style={{ width: "85%", textAlign: "justify" }}
                        >
                          <div className="col-xs-2"></div>
                          <div className="pm-earned ">
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; This is to certify that
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" style={{paddingLeft: 50, paddingRight: 50}}>
                              <span className="pm-name-text bold">
                                {this.state.data.name}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text padding-0 cursive">
                              {this.state.data.gender == "MALE" ? "Son" : "Daughter"} of Sri/Late 
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0 text-center" style={{paddingLeft: 50, paddingRight: 50}}>
                              <span className="pm-name-text bold">
                                {this.state.data.father_name}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text padding-0 cursive">
                              {this.state.data.is_studying == "YES" ? "is" : "was"} student of this college in the 
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0 text-center">
                              <span className="pm-name-text bold">
                                {this.goodSemester(
                                  this.state.data.course,
                                  this.state.data.semester
                                )}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text padding-0 cursive">
                              class of 
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0 text-center">
                              <span className="pm-name-text bold">
                              {this.goodSemesterNew(
                                  this.state.data.course,
                                  0
                                )}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text padding-0 cursive">
                              in the year
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0 text-center">
                              <span className="pm-name-text bold">
                                {this.state.data.year}
                              </span>
                            </span>
                          </div>
                          <div className="col-xs-2"></div>
                          <div className="col-xs-12"></div>

                          <div className="pm-earned text-center">
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              So far as I know {this.state.data.gender == "MALE" ? "he" : "she"} bears a good moral character. I wish {this.state.data.gender == "MALE" ? "him" : "her"} success in life.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}


                  {this.state.data.type_code == "MPC" && (
                    <div>
                      <div
                        className="col-xs-12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className="row"
                          style={{ width: "85%", textAlign: "justify" }}
                        >
                          <div className="col-xs-2"></div>
                          <div className="pm-earned ">
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Certified that 
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" style={{paddingLeft: 50, paddingRight: 50}}>
                              <span className="pm-name-text bold">
                                {this.state.data.name}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              bearing Roll No
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" style={{paddingLeft: 50, paddingRight: 50}}>
                              <span className="pm-name-text bold">
                                {this.state.data.roll_no}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              Registration No
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.state.data.registration_no}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              of GU for the Session
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.state.data.registration_year}
                              </span>
                            </span>

                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              has passed the {this.goodSemesterNew(this.state.data.semester)} in 
                            </span>

                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.state.data.subject}
                              </span>
                            </span>

                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              under the Gauhati University held in the month of
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.state.data.passing_month} -  {this.state.data.year}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              from this college with CGPA
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.state.data.cgpa}
                              </span>
                            </span>


                           
                          </div>
                          <div className="col-xs-2"></div>
                          <div className="col-xs-12"></div>

                          <div className="pm-earned text-center">
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              I wish {this.state.data.gender == "MALE" ? "him" : "her"} success in {this.state.data.gender == "MALE" ? "his" : "her"} life.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}


                  {this.state.data.type_code == "TC" && (
                    <div>
                      <div
                        className="col-xs-12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className="row"
                          style={{ width: "85%", textAlign: "justify" }}
                        >
                          <div className="col-xs-2"></div>
                          <div className="pm-earned ">
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is to certify that
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" style={{paddingLeft: 50, paddingRight: 50}}>
                              <span className="pm-name-text bold">
                                {this.state.data.name}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              Father's Name
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" style={{paddingLeft: 50, paddingRight: 50}}>
                              <span className="pm-name-text bold">
                                {this.state.data.father_name}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              of this institution {this.state.data.is_studying == "YES" ? "is studying" : "has passed"} in 
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.goodSemesterNew(this.state.data.course,this.state.data.semester)}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            {!["HSA","HSS","HSC"].includes(this.state.data.course) && <><span className="pm-earned-text col-sx-12 padding-0 cursive">
                              Subject
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.state.data.subject}
                              </span>
                            </span>
                            </>}
                            {["HSA","HSS","HSC"].includes(this.state.data.course) && <><span className="pm-earned-text col-sx-12 padding-0 cursive">
                              bearing
                            </span>
                         
                            
                            </>}

                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              Roll No.
                            </span>

                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.state.data.roll_no}
                              </span>
                            </span>

                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              securing
                            </span>

                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.state.data.cgpa}
                              </span>
                            </span>

                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              {!["HSA","HSS","HSC"].includes(this.state.data.course) ? 'CGPA/Class' : 'Marks'} in the year
                            </span>

                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.state.data.year}
                              </span>
                            </span>

                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              under the {!["HSA","HSS","HSC"].includes(this.state.data.course) ? 'Gauhati University' : 'Assam Higher Secondary Education Council'}. We do not have any objection to {this.state.data.gender == "MALE" ? "his" : "her"} Transfer from this institution. {this.state.data.gender == "MALE" ? "His" : "Her"} conduct was good while studying in this institution, and nothing is known against {this.state.data.gender == "MALE" ? "his" : "her"} character. 
                            </span>
                            


                           
                          </div>
                          <div className="col-xs-2"></div>
                          <div className="col-xs-12"></div>

                          <div className="pm-earned text-center">
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              We wish {this.state.data.gender == "MALE" ? "him" : "her"} a bright future.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}


                  {this.state.data.type_code == "PPC" && (
                    <div>
                      <div
                        className="col-xs-12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className="row"
                          style={{ width: "85%", textAlign: "justify" }}
                        >
                          <div className="col-xs-2"></div>
                          <div className="pm-earned ">
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Certified that
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" style={{paddingLeft: 50, paddingRight: 50}}>
                              <span className="pm-name-text bold">
                                {this.state.data.name}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                             Roll No
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" style={{paddingLeft: 50, paddingRight: 50}}>
                              <span className="pm-name-text bold">
                                {(this.state.data.university_roll_no == "" || this.state.data.university_roll_no == null) ? this.state.data.roll_no : this.state.data.university_roll_no}
                                
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              has passed the
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.goodSemesterNew(this.state.data.course,this.state.data.semester)}
                              </span>
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              Final Examination from this College under the Gauhati University in
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.state.data.year}
                              </span>
                            </span>

                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              with CGPA
                            </span>

                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.state.data.cgpa}
                              </span>
                            </span>

                            &nbsp; &nbsp;
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              in 
                            </span>
                            &nbsp; &nbsp;
                            <span className="pm-certificate-name underline margin-0" >
                              <span className="pm-name-text bold">
                                {this.state.data.subject}
                              </span>
                            </span>.


                           
                          </div>
                          <div className="col-xs-2"></div>
                          <div className="col-xs-12"></div>

                          <div className="pm-earned text-center">
                            <span className="pm-earned-text col-sx-12 padding-0 cursive">
                              So far as I know {this.state.data.gender == "MALE" ? "he" : "she"} bears a good moral character. I wish {this.state.data.gender == "MALE" ? "him" : "her"} success in life.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}



                  <div className="row pm-certificate-body">
                    <div className="col-xs-12">
                      <div className="row">
                        <div className="pm-certificate-footer" style={{position:"absolute", left: "50%", paddingTop: "150px"}}>
                          <div className="col-xs-6 pm-certified col-xs-6">
                            
                            
                            <span class="">Verified On </span>
                            <span class="bold">
                              {this.state.data.verification_status_on}
                            </span>
                            <br/>
                            <span class="">Library Verified On </span>
                            <span class="bold ">
                              {this.state.data.library_verification_on}
                            </span>
                            <br/>
                            <span class="">Generated On </span>
                            <span class="bold">
                              {this.state.data.generated_on}
                            </span>
                          </div>

                          <div className="col-xs-4"></div>
                          <div className="col-xs-6 pm-certified col-xs-6 text-center">
                            
                            <span className="pm-empty-space block underline">
                            <img src={"/images/pc-prince-sign.png"} style={{width: 150, marginTop: -30}} />
                            </span>
                            <span className="bold block">
                              Principal, Pragjyotish College
                            </span>
                            <span class="bold ">
                              {this.state.data.principal_status_on}
                            </span>
                          </div>
                        </div>

                        <div className="pm-certificate-footer" style={{position:"absolute", left: "50%", top: "60mm"}}>
                          <div className="col-xs-12 pm-certified col-xs-12 text-center">
                            
                            <span class="bold block underline">
                              
                            </span>
                            <span class="block"><i>This is a computer generated certificate, so physical signature is not required. To obtain the original copy, you can visit the office. A QR code is provided for certificate validation; you can scan it to validate the authenticity. For anymore information email us at: <a style={{color: "blue"}}>pragcollege@yahoo.co.in</a></i></span>
                            
                          </div>

                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="watermark-new">
                  <img src="/icon512.png" className="watermark-new-img" />
                </div>
                </div>
                
          
            </div>
          )}

          {this.state.status == false && (
            <div className="reg-form">
              <Result
                status="error"
                title={`Certificate #${this.state.certificate_no} not found !!`}
                subTitle="It seems like the certificate is not found on the system."
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default CertificatePrintPage;
