import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import HomePage from './Containers/HomePage';
import ScrollToTop from './Components/ScrollToTop';
import VerificationPage from './Containers/VerificationPage';
import CertificatePrintPage from './Containers/CertificatePrintPage';

class App extends Component {
  render() {
    return (
      <Router history={history} onUpdate={() => window.scrollTo(0, 0)} >
        <ScrollToTop>
          <div>
            <Switch>
              <Route name="home" exact path="/" component={HomePage} />
              <Route name="home" exact path="/certificate-verfication" render={(props) => <VerificationPage {...props} />} />
              <Route name="home" exact path="/certificate-print" render={(props) => <CertificatePrintPage {...props} />} />
            </Switch>
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
